<template>
 <TableStyleWrapper>
  <TableWrapper class="table-responsive">
   <a-table
    :columns="trailTableColumns"
    :dataSource="trailTableData"
    :loading="loadingTrails"
    :pagination="{
          defaultPageSize: state.trail.pageSize,
          total: count,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${count} items`,
        }"
    @change="paginationChange"
   />
  </TableWrapper>
 </TableStyleWrapper>
</template>
<script>
import {TableStyleWrapper} from '../style';
import {TableWrapper} from '../../styled';
import {computed, defineComponent} from 'vue';
import {useStore} from "vuex";
import moment from 'moment';

const trailTableColumns = [
 {
  title: 'User ID',
  dataIndex: 'userID',
  key: 'userID',
 },
 {
  title: 'Model',
  dataIndex: 'modelType',
  key: 'modelType',
 },
 {
  title: 'Model ID',
  dataIndex: 'modelID',
  key: 'modelID',
 },
 {
  title: 'Event',
  dataIndex: 'event',
  key: 'event',
 },
 {
  title: 'IP',
  dataIndex: 'ipAddress',
  key: 'ipAddress',
 },
 {
  title: 'Timestamp',
  dataIndex: 'timestamp',
  key: 'timestamp',
 },
 {
  title: '',
  dataIndex: 'actions',
  key: 'actions',
 },

];
const TrailListTable = defineComponent({
 name: 'TrailListTable',
 components: {TableStyleWrapper, TableWrapper},
 setup() {
  const {state, dispatch} = useStore();
  const trails = computed(() => state.trail.trails);
  const currentPage = computed(() => state.trail.currentPage);
  const count = computed(() => state.trail.count);
  const loadingTrails = computed(() => state.trail.loadingTrails);
  const trailTableData = computed(() =>
   trails.value.map((trail, index) => {
    const {modelID, modelType, userID, createTimestamp, event, ipAddress} = trail;

    return {
     key: index,
     userID: userID,
     modelID: modelID,
     modelType: modelType,
     event: (<span className="status-text" style={{color: 'white', backgroundColor: '#000000'}}>{event}</span>),
     ipAddress: ipAddress,
     timestamp: <span>{moment(createTimestamp).format('DD-MM-YYYY HH:mm:ss')}</span>,
    };
   }),
  );


  const paginationChange = async (event) => {
   await dispatch('setCurrentPageTrail', event.current);
   await dispatch('getTrails', {page: event.current, pageSize: state.trail.pageSize});
  }

  return {trailTableColumns, trailTableData, loadingTrails, paginationChange, count, currentPage, state};
 },
});

export default TrailListTable;
</script>
